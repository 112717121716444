import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

type SpacerProps = {
  numberOfSpaces: number;
};

const RowSpacer = styled.div<SpacerProps>(({ numberOfSpaces }) => ({
  width: getSpacing(numberOfSpaces),
}));
const ColumnSpacer = styled.div<SpacerProps>(({ numberOfSpaces }) => ({
  height: getSpacing(numberOfSpaces),
}));

export const Spacer = {
  Row: RowSpacer,
  Column: ColumnSpacer,
};
