import { ItemInput } from 'models/itemInput';

export const removeAllDuplicateObjects = (arr: ItemInput[]): ItemInput[] => {
  const unique: ItemInput[] = [];

  arr.map(item => {
    let count = 0;

    for (let i = 0; i < arr.length; i++) {
      if (JSON.stringify(arr[i]) === JSON.stringify(item)) {
        count = count + 1;
      }
    }

    if (count === 1) {
      unique.push(item);
    }
  });
  return unique;
};
