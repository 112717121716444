export const isString = (str: unknown): str is string => {
  return typeof str === 'string';
};

export const isStringReturnString = (str: unknown): string => {
  if (isString(str)) {
    return str;
  }

  return '';
};
