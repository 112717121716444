import { DropdownValue, FormikSetFieldValue } from 'models/global';
import { ItemInput } from 'models/itemInput';
import {
  areasOfSpecialisationVar,
  sitesOfCareVar,
} from 'services/apollo/typePolicies/queryPolicies';
import { idsToObjectValues } from 'utils/dataTransforms';
import { formatForDropdown } from 'utils/formatForDropdown/formatForDropdown';
import { removeAllDuplicateObjects } from 'utils/removeAllDuplicateObjects';
import { CategoryWithSitesOfCare } from './AddSOCForm.model';

export const handleCheckboxSelect = (
  setFieldValue: FormikSetFieldValue,
  value: string,
  selected: string[],
  field: string,
): void => {
  const valueAlreadyExists = selected.includes(value);
  if (valueAlreadyExists) {
    const newValue = selected.filter(item => item !== value);
    setFieldValue(field, newValue, true);
  } else {
    setFieldValue(field, [...selected, value], true);
  }
};

export const getAosRelatedSocs = (
  aosValues: ItemInput[],
  oldValues?: { aos: ItemInput[]; soc: ItemInput[] },
): ItemInput[] => {
  let addSocs: string[] = [];
  if (oldValues) {
    addSocs = getAdditionalSocs(oldValues.aos, oldValues.soc);
  }

  const socIds = getSocIdsFromAos(aosValues);
  const socs = idsToObjectValues([...socIds, ...addSocs], sitesOfCareVar());
  return socs;
};

// Removes duplicates and sets in common section
export const setCommonSitesOfCare = (currentAosValues: ItemInput[]): DropdownValue[] => {
  const allSitesOfCare = sitesOfCareVar();

  const siteOfCareIdsFromSelectedAos = getSocIdsFromAos(currentAosValues);

  const commonSiteOfCareIds: string[] = Array.from(
    new Set(
      siteOfCareIdsFromSelectedAos.filter(
        (soc, index) => siteOfCareIdsFromSelectedAos.indexOf(soc) !== index,
      ),
    ),
  );

  const commonSiteOfCareObjects: ItemInput[] = commonSiteOfCareIds.map(soc => allSitesOfCare[soc]);

  return formatForDropdown(commonSiteOfCareObjects);
};

export const setSelectedAosSitesOfCare = (
  currentAosValues: ItemInput[],
): CategoryWithSitesOfCare[] => {
  const allAreasOfSpecialty = areasOfSpecialisationVar();
  const allSitesOfCare = sitesOfCareVar();

  const siteOfCareIdsFromSelectedAos = getSocIdsFromAos(currentAosValues);

  const siteOfCareObjects: ItemInput[] = siteOfCareIdsFromSelectedAos.map(
    soc => allSitesOfCare[soc],
  );

  const uniqueSiteOfCareObjects: ItemInput[] = removeAllDuplicateObjects(siteOfCareObjects);

  const categoriesWithSitesOfCare = currentAosValues.map(aos => {
    const aosSitesOfCare = allAreasOfSpecialty[aos.id].siteOfCareIds;
    const filteredUniqueSiteOfCareObjects = uniqueSiteOfCareObjects.filter(item =>
      aosSitesOfCare?.includes(item.id),
    );

    return {
      category: aos.name.en,
      sitesOfCare: formatForDropdown(filteredUniqueSiteOfCareObjects),
    };
  });

  // Only return categories where there are definitely sites of care.
  const final = categoriesWithSitesOfCare.filter(item => item.sitesOfCare.length !== 0);

  return final;
};

export const setAdditionalSitesOfCare = (
  currentAosValues: ItemInput[],
): CategoryWithSitesOfCare[] => {
  const allSitesOfCare = sitesOfCareVar();

  const siteOfCareIdsFromSelectedAos = getSocIdsFromAos(currentAosValues);

  // Get just the areas of specialty that aren't selected.
  const filteredAreasOfSpecialty = filterAos(currentAosValues);

  // Check if the filtered areas of specialty have SOC from the selected AOS and remove them.
  const filteredAosWithoutSharedSitesOfCare = filteredAreasOfSpecialty.map(aos => {
    return {
      id: aos.id,
      name: aos.name,
      siteOfCareIds: aos.siteOfCareIds?.filter(soc => !siteOfCareIdsFromSelectedAos.includes(soc)),
    };
  });

  const final: CategoryWithSitesOfCare[] = filteredAosWithoutSharedSitesOfCare.map(aos => {
    const sitesOfCare: ItemInput[] = aos.siteOfCareIds?.map(soc => allSitesOfCare[soc]) || [];
    return {
      category: aos.name.en,
      sitesOfCare: formatForDropdown(sitesOfCare),
    };
  });

  // Only return categories where there are definitely sites of care.
  return final.filter(item => !!item.sitesOfCare.length);
};

export const getAdditionalSocs = (aosValues: ItemInput[], socs: ItemInput[]): string[] => {
  const allAreasOfSpecialty = areasOfSpecialisationVar();
  const aosSocIds: string[] = [];
  const socIds = socs?.map(i => i.id);
  const addSocsIds: string[] = [];

  // Get total soc ids related to current aos values
  aosValues.forEach(val => {
    const aos = allAreasOfSpecialty[val.id];
    aosSocIds.push(...(aos.siteOfCareIds as string[]));
  });

  // Find any additioal socIds
  aosValues.forEach(() => {
    socIds?.forEach(socId => {
      if (!aosSocIds.includes(socId)) {
        addSocsIds.push(socId);
      }
    });
  });

  // Remove duplicates
  return Array.from(new Set(addSocsIds));
};

const getSocIdsFromAos = (currentAosValues: ItemInput[]): string[] => {
  const siteOfCareIdsFromSelectedAos: string[] = [];

  currentAosValues.forEach(aos => {
    siteOfCareIdsFromSelectedAos.push(...(areasOfSpecialisationVar()[aos.id].siteOfCareIds ?? []));
  });

  return siteOfCareIdsFromSelectedAos;
};

const filterAos = (currentAosValues: ItemInput[]) => {
  const allAreasOfSpecialty = areasOfSpecialisationVar();

  const filteredAreasOfSpecialty = Object.values(allAreasOfSpecialty).filter(aos => {
    let selected = false;
    for (let i = 0; i < currentAosValues.length; i++) {
      if (currentAosValues[i].id === aos.id) {
        selected = true;
      }
    }

    if (!selected) {
      return aos;
    }
  });

  return filteredAreasOfSpecialty;
};
