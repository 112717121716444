import { DropdownValue, FormikSetFieldValue } from 'models/global';
import { areasOfPracticeVar } from 'services/apollo/typePolicies/queryPolicies';
import { AosDataType } from './AddAOSForm';

export const handleSelect = (
  setFieldValue: FormikSetFieldValue,
  selected: DropdownValue[],
  fullList: DropdownValue[],
  field: string,
): void => {
  if (selected && selected.length > 0 && fullList) {
    const newValues = fullList.filter(item =>
      selected.find((option: DropdownValue) => option.value === item.value),
    );
    setFieldValue(field, newValues, true);
  } else {
    setFieldValue(field, [], true);
  }
};

export const getRelevantAOPs = (
  aos: DropdownValue[],
  data: AosDataType[],
  filterAops?: string[],
): DropdownValue[] => {
  const aopOptions: DropdownValue[] = [];

  if (aos[0]) {
    const aosId = aos[0].value;
    const aosArray = data.filter((item: AosDataType) => item.id === aosId);
    let aopIds: string[] | undefined;
    if (filterAops) {
      aopIds = filterAops.filter(id => aosArray[0].areaOfPracticeIds?.includes(id));
    } else {
      aopIds = aosArray[0].areaOfPracticeIds;
    }
    aopIds &&
      aopIds.forEach((id: string) => {
        const aop = areasOfPracticeVar()[id];
        aopOptions.push({
          label: aop.name.en,
          value: aop.id,
          areaOfSpecialisationId: aop.areaOfSpecialisationId,
        });
      });
  }
  return aopOptions;
};
