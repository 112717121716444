import { AosDataType } from 'components/AddAOSForm/AddAOSForm';
import { ClinicalAttribute } from 'models/global';
import { AreaOfSpecialisation } from '../../../../types/graphql';

export const idsToStringValues = (
  ids: string[],
  attributes: Record<string, ClinicalAttribute>,
): string[] => ids.map(id => attributes[id]?.name['en']);

export const idsToObjectValues = (
  ids: string[],
  attributes: Record<string, AreaOfSpecialisation | ClinicalAttribute>,
): AosDataType[] => ids.map(id => attributes[id]);
