import { AOSFormValues } from 'components/AddAOSForm/AddAOSForm';
import { AddArticleFormValues } from 'components/AddArticleModal/AddArticleModal.model';
import { AddEmployeeFormValues } from 'components/AddEmployeeModal/AddEmployeeModal.model';
import { getAosRelatedSocs } from 'components/AddSOCForm/AddSOCForm.helpers';
import { AddCompanyFormValues } from 'components/AdminCompanyForm/types';
import { EditCompanyFormValues } from 'components/AdminEditCompanyModal/AdminEditCompanyModal';
import uniqBy from 'lodash/uniqBy';
import { FormikSetFieldValue } from 'models/global';
import { formatFromDropdown } from 'utils/formatForDropdown/formatForDropdown';

export const updateAos = (
  aosValues: AOSFormValues,
  values:
    | AddArticleFormValues
    | AddEmployeeFormValues
    | AddCompanyFormValues
    | EditCompanyFormValues,
  setFieldValue: FormikSetFieldValue,
): void => {
  const initialLength = values.areasOfPractice.map(item => item.areaOfSpecialisationId).length;
  const newLength = aosValues.productGroups.map(item => item.areaOfSpecialisationId).length;
  const initialAop =
    initialLength > newLength
      ? values.areasOfPractice.filter(
          item => item.areaOfSpecialisationId !== aosValues.aos[0].value,
        )
      : values.areasOfPractice;
  const aos = uniqBy([...values.areasOfSpecialisation, ...formatFromDropdown(aosValues.aos)], 'id');
  const aops = uniqBy([...initialAop, ...formatFromDropdown(aosValues.productGroups)], 'id');
  setFieldValue('areasOfPractice', aops);
  setFieldValue('areasOfSpecialisation', aos);
  setFieldValue('sitesOfCare', getAosRelatedSocs(aos));
};
