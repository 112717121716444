import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${getSpacing(2)};
`;

export const Content = styled.div<{ isButton?: boolean }>`
  padding: ${getSpacing(4)};
  border-radius: 4px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${defaultTheme.colors.separator};
  width: 100%;
  ${props => props.isButton && `cursor: pointer;`}
`;

export const Heading = styled.p`
  font-weight: 500;
`;

export const Subtitle = styled.span`
  color: ${defaultTheme.colors.secondaryGrey};
`;

export const Bin = styled.div`
  background-color: ${({ theme }) => theme.colors.separator};
  margin-left: ${getSpacing(2)};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;
