import { client } from 'services/client';
import Compressor from 'compressorjs';
import { REST_API_PATHS } from 'constants/paths';
import { logError } from 'services/logging';
import { CompanyLogoBackendType } from 'components/AdminCompanyForm/types';
import { isStringReturnString } from '../../types/guards/isString';

type PostImageResponse = {
  url: string;
};

type ImageData = {
  base64ImageString: string | ArrayBuffer | null;
  type: CompanyLogoBackendType;
  contentType: Blob['type'];
  employeeId?: string;
};

export const convertBlobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(isStringReturnString(reader.result).split(',')[1]);
    reader.onerror = error => reject(error);
  });

export const uploadNewsFeedItemImage = async (img: string): Promise<string> => {
  try {
    const data = {
      base64ImageString: img,
    };
    const withAuth = true;
    const result = await client.post<PostImageResponse>(
      REST_API_PATHS.newsFeedItemImageUpload,
      data,
      withAuth,
    );
    return result.url;
  } catch (error) {
    logError({ error, errorName: 'UploadNewsFeedItemImageError' });
    throw error;
  }
};

export const uploadProfileImage = async (data: ImageData): Promise<string | null> => {
  const dataString = JSON.stringify(data);
  try {
    const result = await client.post<PostImageResponse>(
      REST_API_PATHS.uploadImage,
      dataString,
      true,
    );
    return result.url;
  } catch (error) {
    logError({ error, errorName: 'ProfileImageUploadError' });
    return null;
  }
};

export const companyLogoUpload = async (data: ImageData): Promise<string | null> => {
  const dataString = JSON.stringify(data);
  try {
    const result = await client.post<PostImageResponse>(
      REST_API_PATHS.uploadImage,
      dataString,
      true,
    );
    return result.url;
  } catch (error) {
    logError({ error, errorName: 'CompanyLogoUploadError' });
    return null;
  }
};

export const compressImage = (file: File): Promise<Blob> => {
  return new Promise<Blob>((resolve, reject) => {
    new Compressor(file, {
      success: (result: Blob) => {
        return resolve(result);
      },
      error: error => {
        return reject(error);
      },
    });
  });
};

export const base64StringToPreviewUrl = (base64String: string): string => {
  return `data:image/png;base64,${base64String}`;
};

export const createCompanyImageData = async (image: File, type: CompanyLogoBackendType) => {
  const compressedBlob = await compressImage(image);
  const base64ImageString = await convertBlobToBase64(compressedBlob);
  return { base64ImageString, type, contentType: image.type };
};
