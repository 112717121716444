import React from 'react';
import { Text } from 'atoms/Text/Text';
import { defaultTheme } from 'constants/theme/defaultTheme';

export const Guidance: React.FC<{ invalid: boolean; text: string }> = ({ invalid, text }) => {
  return (
    <Text.caption3 color={invalid ? defaultTheme.colors.alertRed : defaultTheme.colors.darkGrey}>
      {text}
    </Text.caption3>
  );
};
