import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

type ErrorType = {
  marginTop?: string;
  marginBottom?: string;
  center?: boolean;
  fullWidth?: boolean;
  noneAbsolute?: boolean;
  isInfo?: boolean;
};

export const Error = styled.p<ErrorType>`
  position: ${props => (!props.noneAbsolute ? 'absolute' : 'static')};
  color: ${props => !props.isInfo && defaultTheme.colors.alertRed};
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : defaultTheme.spacing(1))};
  margin-bottom: ${props => (props.marginBottom ? `${props.marginBottom}px` : `0`)};
  font-weight: ${props => (props.isInfo ? 500 : 600)};
  font-size: 12px;
  line-height: 150%;
  text-align: ${({ center }) => (center ? 'center' : 'initial')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'initial')};
`;
