import React, { InputHTMLAttributes } from 'react';
import { Styles } from './styles';
import { Error } from 'atoms/Error';

type InputProps = {
  error?: string;
  isInfo?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input: React.FC<InputProps> = ({ error, isInfo, ...rest }) => {
  return (
    <Styles.Wrapper>
      <Styles.StyledInput {...rest} isError={!!error && !isInfo} />
      {error && (
        <Error marginTop="0" isInfo={isInfo}>
          {error}
        </Error>
      )}
    </Styles.Wrapper>
  );
};
