import { getUserCredentials } from './auth';
import axios, { AxiosRequestConfig } from 'axios';

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';
const backendBaseUrl: string = process.env.API_ENDPOINT as string;

class Client {
  baseUrl: string;
  tokenKey = 'token';

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async makeRequest<T = string>(
    method: Method,
    endpoint: string,
    data: unknown | FormData | null,
    withAuth: boolean,
    timeout?: number,
  ): Promise<T> {
    const url = `${this.baseUrl}/${endpoint}`;
    let headers = {};
    if (withAuth) {
      const token = await this.getToken();
      if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` };
      } else {
        throw Error('Missing Token');
      }
    }

    const config: AxiosRequestConfig = {
      url,
      method: method,
      headers,
      data,
      // 0 is default axios timeout
      timeout: timeout || 0,
    };
    const response = await axios.request<T>(config);
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        throw Error('Something went wrong when uploading the file');
      }
    } catch (e) {
      throw Error('Something went wrong when uploading the file');
    }
  }

  getToken = async (): Promise<string | undefined> => {
    return await getUserCredentials();
  };

  post<T = unknown>(
    endpoint: string,
    data: unknown,
    withAuth = true,
    timeout?: number,
  ): Promise<T> {
    return this.makeRequest<T>('post', endpoint, data, withAuth, timeout);
  }

  get(endpoint: string, withAuth = true, timeout?: number) {
    return this.makeRequest('get', endpoint, null, withAuth, timeout);
  }

  noAuthGet(endpoint: string) {
    return this.makeRequest('get', endpoint, null, false);
  }

  put(endpoint: string, data: unknown): Promise<string> {
    return this.makeRequest('put', endpoint, data, true);
  }
}
export const client = new Client(backendBaseUrl);
