import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const Styles = {
  HeaderWrapper: styled.div`
    margin-bottom: ${defaultTheme.spacing(6)};
  `,
  Label: styled.div`
    margin-bottom: ${defaultTheme.spacing(2)};
  `,
  Wrapper: styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    position: relative;
    padding: ${defaultTheme.spacing(8)} ${defaultTheme.spacing(10)};
  `,
  Footer: styled.div<{ fixedFooter?: boolean }>`
    margin-top: auto;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    ${props =>
      props.fixedFooter &&
      `position: absolute; bottom: 0; left: 0; right: 0; margin-top: 0; border-top: 1px solid ${
        defaultTheme.colors.separator
      }; padding: ${defaultTheme.spacing(4)}`}
  `,
  Back: styled.p<{ fixedFooter?: boolean }>`
    font-weight: 500;
    font-size: 14px;
    ${props => props.fixedFooter && `margin-left: ${defaultTheme.spacing(4)};`}
    :hover {
      cursor: pointer;
    }
  `,
  FieldContent: styled.div`
    min-height: 100px;
  `,
};
