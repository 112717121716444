import React, { InputHTMLAttributes } from 'react';
import { StyledTextArea } from './styles';
import { Text } from 'atoms/Text/Text';

type TextAreaProps = {
  label?: string;
  rows?: number;
  testId?: string;
  maxChars?: number;
} & InputHTMLAttributes<HTMLTextAreaElement>;

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  rows,
  onChange,
  testId,
  maxChars,
  ...rest
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (maxChars && e.target.value.length >= maxChars) return;
    onChange && onChange(e);
  };

  return (
    <>
      {label && <Text.button3>{label}</Text.button3>}
      <StyledTextArea data-testid={testId} rows={rows || 3} onChange={handleChange} {...rest} />
    </>
  );
};
