import { TEST_IDS } from 'constants/testIds';
import React from 'react';
import { Content, Heading, Subtitle, Wrapper, Bin } from './styles';

type EditableRowProps = {
  title: string;
  subtitle: string;
  onEditClick: () => void;
  testId?: string;
  onDeleteClick?: () => void;
};

const EditableRow: React.FC<EditableRowProps> = ({
  title,
  subtitle,
  onEditClick,
  onDeleteClick,
  testId,
}) => {
  return (
    <Wrapper>
      <Content onClick={onEditClick} isButton={!!onEditClick}>
        <Heading data-testid={testId}>
          {title}
          <Subtitle> - {subtitle}</Subtitle>
        </Heading>
        <svg
          width="9"
          height="15"
          viewBox="0 0 9 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.02279 7.14371L8.02269 7.14361L2.19201 1.32906L2.19196 1.32901C1.90318 1.04119 1.4357 1.04173 1.1475 1.33045L1.14744 1.3305C0.859295 1.61936 0.860115 2.08713 1.14889 2.37512L1.14889 2.37513L6.45499 7.66658L1.1487 12.958L1.14867 12.958C0.859951 13.246 0.859129 13.7136 1.14722 14.0025L1.1473 14.0025C1.29161 14.1471 1.48131 14.2196 1.67034 14.2196C1.85883 14.2196 2.04783 14.1476 2.19193 14.004L2.19198 14.0039L8.02248 8.18973C8.1618 8.05112 8.23979 7.86269 8.23979 7.66658C8.23979 7.47017 8.16146 7.28215 8.02279 7.14371Z"
            fill="#A6A7B3"
            stroke="#A6A7B3"
            strokeWidth="0.5"
          />
        </svg>
      </Content>
      {onDeleteClick && (
        <Bin onClick={onDeleteClick} data-testid={TEST_IDS.aosRow.deleteAosButton}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.25 4.5H3.75H15.75"
              stroke="#A6A7B3"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5H14.25Z"
              stroke="#A6A7B3"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 8.25V12.75"
              stroke="#A6A7B3"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5 8.25V12.75"
              stroke="#A6A7B3"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Bin>
      )}
    </Wrapper>
  );
};

export default EditableRow;
